import { gql } from '@apollo/client'

export const NODE_REWARD_AND_FEE_BY_DAYS = gql`
  query nodeRewardAndFeeByDays($from: DateTime!, $to: DateTime!) {
    nodeRewardAndFeeByDays(from: $from, to: $to) {
      day
      fee
      reward
    }
  }
`
