import { PhaseStatus } from 'common/types'

export const PhaseStatusLabels: Record<PhaseStatus, string> = {
  [PhaseStatus.ACTIVE]: 'Active',
  [PhaseStatus.COMPLETED]: 'Completed',
  [PhaseStatus.NEW]: 'New',
}

export enum View {
  LIST = 'list',
  GRAPH = 'graph',
}
