import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { useMutation } from '@apollo/client'
import { Box, Snackbar, Typography } from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Alert, ModalWrapper } from 'common'
import { AlertMessage, Phase } from 'common/types'
import { ALERT_DELAY, DEFAULT_DATE_FORMAT } from 'constants/params'
import { FormikProps, useFormik } from 'formik'
import { CREATE_PHASE, UPDATE_PHASE } from 'graphql/phase/mutations'
import {
  addPhaseToPhaseQueryUpdater,
  updatePhaseQueryUpdater,
} from 'graphql/phase/updaters'
import { DateTime } from 'luxon'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

interface Props {
  open: boolean
  phase?: Phase | null
  onClose?: () => void
  onUpdateSelectedPhase?: Dispatch<SetStateAction<Phase | null>>
}

enum FIELDS {
  END_DATE = 'endDate',
  START_DATE = 'startDate',
}

interface InitialValues {
  [FIELDS.END_DATE]: DateTime | null
  [FIELDS.START_DATE]: DateTime | null
}

function CreatePhaseModal({
  phase,
  open,
  onClose,
  onUpdateSelectedPhase,
}: Props) {
  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })
  const [loading, setLoading] = useState(false)

  const [createPhase, { loading: createLoading }] = useMutation(CREATE_PHASE)

  const [updatePhase, { loading: updateLoading }] = useMutation(UPDATE_PHASE)

  const validationSchema = yup.object({
    [FIELDS.START_DATE]: yup
      .string()
      .required('Start date is required')
      .nullable(),
    [FIELDS.END_DATE]: yup.string().required('End date is required').nullable(),
  })

  const initialValues: InitialValues = {
    [FIELDS.START_DATE]: phase?.startDate
      ? DateTime.fromISO(phase?.startDate)
      : null,
    [FIELDS.END_DATE]: phase?.endDate ? DateTime.fromISO(phase?.endDate) : null,
  }

  const {
    values,
    errors,
    handleSubmit,
    resetForm,
    setFieldValue,
  }: FormikProps<InitialValues> = useFormik<InitialValues>({
    initialValues,
    validationSchema,
    onSubmit: values => submit(values),
    validateOnChange: false,
    enableReinitialize: true,
  })

  const submit: (values: InitialValues) => Promise<void> = useCallback(
    async (values: InitialValues) => {
      setLoading(true)
      const alertText = phase?.id
        ? 'Phase updated successfully'
        : 'Phase created successfully'

      const phaseVariables = {
        [FIELDS.START_DATE]: values?.[FIELDS.START_DATE]
          ? values[FIELDS.START_DATE]?.toUTC()
          : null,
        [FIELDS.END_DATE]: values?.[FIELDS.END_DATE]
          ? values[FIELDS.END_DATE]?.toUTC()
          : null,
      }
      try {
        if (phase?.id) {
          const response = await updatePhase({
            variables: {
              id: phase.id,
              ...phaseVariables,
            },
            update(cache, { data }) {
              return updatePhaseQueryUpdater(cache, data?.updatePhase)
            },
          })

          if (response?.data?.updatePhase) {
            onUpdateSelectedPhase?.(response.data.updatePhase)
          }
        } else {
          const response = await createPhase({
            variables: { ...phaseVariables },
            update(cache, { data }) {
              return addPhaseToPhaseQueryUpdater(cache, data?.createPhase)
            },
          })
          if (response?.data?.createPhase) {
            onUpdateSelectedPhase?.(response.data.createPhase)
          }
        }

        setAlert({
          isOpen: true,
          text: alertText,
          alertColor: 'success',
        })
        onClose?.()
        resetForm()
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      } finally {
        setLoading(false)
      }
    },
    [
      createPhase,
      onClose,
      onUpdateSelectedPhase,
      phase,
      resetForm,
      updatePhase,
    ],
  )

  const handleCloseModal = useCallback(() => {
    onClose?.()
    resetForm()
  }, [onClose, resetForm])

  const areDatesValid = useMemo(() => {
    const startDateTime = values[FIELDS.START_DATE]
    const endDateTime = values[FIELDS.END_DATE]

    if (!startDateTime || !endDateTime) {
      return true
    }

    return startDateTime < endDateTime
  }, [values])

  const isEdit = useMemo(() => !!phase?.id, [phase])

  return (
    <>
      <ModalWrapper
        buttonText={isEdit ? 'Edit' : 'Create'}
        disabled={loading || createLoading || updateLoading}
        open={open}
        title={isEdit ? 'Update phase' : 'Create phase'}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
      >
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            format={DEFAULT_DATE_FORMAT}
            label="Start date"
            slotProps={{
              textField: {
                helperText: errors[FIELDS.START_DATE],
                error: !!errors[FIELDS.START_DATE],
                name: FIELDS.START_DATE,
                size: 'small',
                sx: { mb: 1, width: '100%' },
                variant: 'standard',
              },
            }}
            value={values[FIELDS.START_DATE]}
            onChange={value => setFieldValue(FIELDS.START_DATE, value)}
          />
          <DatePicker
            format={DEFAULT_DATE_FORMAT}
            label="End date"
            slotProps={{
              textField: {
                helperText: errors[FIELDS.END_DATE],
                error: !!errors[FIELDS.END_DATE],
                name: FIELDS.END_DATE,
                size: 'small',
                sx: { mb: 1, width: '100%' },
                variant: 'standard',
              },
            }}
            value={values[FIELDS.END_DATE]}
            onChange={value => setFieldValue(FIELDS.END_DATE, value)}
          />
        </LocalizationProvider>
        <Box>
          {!areDatesValid && (
            <Typography color="error" fontSize="12px" sx={{ mt: 1 }}>
              Please check dates. The end date should be later than the start
              date.
            </Typography>
          )}
        </Box>
      </ModalWrapper>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default CreatePhaseModal
