import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import { TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import PageWrapper from 'common/pageWrapper'
import { NODE_REWARD_AND_FEE_BY_DAYS } from 'graphql/rewards/queries/nodeRewardAndFeeByDays'
import { DateTime } from 'luxon'
import numeral from 'numeral'

import DaysOfMonth, { NodeRewardAndFeeByDays } from './daysOfMonth'

const DATE_FORMAT = 'yyyy-MM'

type Total = {
  fee: number
  reward: number
}

function Calendar() {
  const [month, setMonth] = useState<DateTime>(DateTime.now())

  const { loading, data } = useQuery(NODE_REWARD_AND_FEE_BY_DAYS, {
    variables: {
      from: month?.startOf('month').toFormat('yyyy-MM-dd'),
      to: month?.endOf('month').toFormat('yyyy-MM-dd'),
    },
    skip: !month,
  })

  const total: Total = useMemo(() => {
    if (!data?.nodeRewardAndFeeByDays) return { fee: 0, reward: 0 }

    return data.nodeRewardAndFeeByDays.reduce(
      (acc: Total, curr: NodeRewardAndFeeByDays) => {
        acc.fee += curr.fee || 0
        acc.reward += curr.reward || 0
        return acc
      },
      { fee: 0, reward: 0 } as Total,
    )
  }, [data])

  const handleDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value) return

      setMonth(DateTime.fromFormat(event.target.value, DATE_FORMAT))
    },
    [],
  )

  return (
    <PageWrapper>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb="24px"
          minHeight="80px"
          sx={{ py: '15px', px: '30px' }}
        >
          <Box display="flex" height="44px" width="100%">
            <Box alignItems="center" display="flex">
              <Typography fontSize="18px" fontWeight="700" mr="24px">
                Calendar
              </Typography>
            </Box>

            <Box
              mr="15px"
              sx={{
                width: '1px',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
              }}
            />

            <Box display="flex" flexDirection="column" width="100%">
              <Box display="flex" mr={5} width="100%">
                <Typography
                  color="#0faa44"
                  fontSize="12px"
                  fontWeight="500"
                  width="86px"
                >
                  MNW balance:
                </Typography>

                <Typography fontSize="12px" fontWeight="400">
                  {numeral(total.reward).format()}
                </Typography>
              </Box>

              <Box display="flex">
                <Typography
                  color="#bd8a04"
                  fontSize="12px"
                  fontWeight="500"
                  width="86px"
                >
                  Matic balance:
                </Typography>

                <Typography fontSize="12px" fontWeight="400">
                  {numeral(total.fee).format()}
                </Typography>
              </Box>
            </Box>

            <Box flexShrink={0} mr={5} width="150px">
              <TextField
                sx={{
                  '& .MuiInputBase-root': { borderRadius: '0' },
                  '& .MuiInputBase-input': {
                    padding: '8px 12px',
                    cursor: 'pointer',
                    borderRadius: '0',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderBottom: '1px solid #afafaf',
                  },
                }}
                type="month"
                value={month.toFormat(DATE_FORMAT)}
                onChange={handleDateChange}
              />
            </Box>
          </Box>
        </Box>

        <Box height="100%" sx={{ px: '30px' }}>
          <DaysOfMonth
            data={data?.nodeRewardAndFeeByDays}
            loading={loading}
            month={month}
          />
        </Box>
      </LocalizationProvider>
    </PageWrapper>
  )
}

export default Calendar
